import * as React from 'react';
import { Link } from 'react-router-dom';
import { TranslateInterface, withTranslation } from 'utils/translation';
import { pages } from 'services/routing';
import Button from 'ui-library/button';
import ErrorPage from 'scenes/errors';

const StreamerNotFound = ({ t }: TranslateInterface) => (
  <ErrorPage
    title={t('errors:errorPage.streamerNotFoundTitle')}
    description={t('errors:errorPage.streamerNotFoundDescription')}
  >
    <Button cta component={Link} to={pages.STREAMERS}>
      {t('errorPage.goToStreamer')}
    </Button>
  </ErrorPage>
);

//imported with loadable
export default withTranslation('errors')(StreamerNotFound);
